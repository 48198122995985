<template>
  <div>
    <v-container fluid class="py-6">
      <div class="d-sm-flex justify-between">
        <div class="d-flex ms-auto">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              py-2
              px-6
              me-2
            "
            color="transparent"
            small
          >
            <v-icon>ni ni-archive-2 me-1</v-icon>
            Export CSV
          </v-btn>
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <table-orders :history_type="history_type"></table-orders>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { history_type_enum } from "@/definitions.js";
import TableOrders from "@/components/CRM/TableOrders";

export default {
  name: "Shop-Orders-List",
  props: {
    history_type: {
      type: Number,
      default: history_type_enum.Recharge,
    },
  },
  components: {
    TableOrders,
  },
};
</script>
